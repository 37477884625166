import type { IconProps } from "@chakra-ui/react";
import { Icon as CIcon } from "@chakra-ui/react";
import type { VFC } from "react";
import type { IconType } from "react-icons";
import { BiCopy } from "react-icons/bi";
import {
  HiCheck,
  HiCheckCircle,
  HiChevronDoubleRight,
  HiChevronDoubleUp,
  HiChevronRight,
  HiClipboardList,
  HiExclamation,
  HiMenu,
  HiOutlineBan,
  HiOutlineCalendar,
  HiOutlineFilter,
  HiOutlineX,
  HiPlusCircle,
  HiSearch,
  HiUser,
} from "react-icons/hi";
import { IoInformationCircle } from "react-icons/io5";
import { MdManageAccounts, MdSpeakerNotes } from "react-icons/md";

const names = [
  "hamburger",
  "close",
  "right",
  "right2x",
  "warning",
  "search",
  "calendar",
  "checked",
  "circle-checked",
  "canceled",
  "filter",
  "double-up",
  "list",
  "user",
  "attention",
  "copy",
  "notes",
  "manage",
  "circle-plus",
] as const;

export type IconName = typeof names[number];

const icons: Record<IconName, IconType> = {
  hamburger: HiMenu,
  close: HiOutlineX,
  right: HiChevronRight,
  warning: HiExclamation,
  search: HiSearch,
  calendar: HiOutlineCalendar,
  checked: HiCheck,
  "circle-checked": HiCheckCircle,
  right2x: HiChevronDoubleRight,
  canceled: HiOutlineBan,
  filter: HiOutlineFilter,
  "double-up": HiChevronDoubleUp,
  list: HiClipboardList,
  user: HiUser,
  attention: IoInformationCircle,
  copy: BiCopy,
  notes: MdSpeakerNotes,
  manage: MdManageAccounts,
  "circle-plus": HiPlusCircle,
};

type Props = IconProps & {
  name: IconName;
};

export const Icon: VFC<Props> = (props) => {
  const { name, ...iconProps } = props;
  return <CIcon as={icons[name]} {...iconProps} />;
};
