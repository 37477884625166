import type { ContainerProps } from "@chakra-ui/react";
import { Box, Container, Flex } from "@chakra-ui/react";
import type { ReactNode, VFC } from "react";
import { isMobile } from "react-device-detect";
import { Footer } from "src/components/ui/layout/Footer";
import { Header } from "src/components/ui/layout/Header";
import { SideMenu } from "src/components/ui/layout/SideMenu";

type Props = ContainerProps & {
  children: ReactNode;
};

export const Layout: VFC<Props> = (props) => {
  const { children, ...containerProps } = props;
  return (
    <Flex direction="column" w="100%" minH="100vh" bg="base" pt="60px">
      <Header />
      {isMobile ? (
        <Flex flex="1" h="full">
          <Box as="main" flex="1">
            <Container maxW="container.xl" overflow="scroll" h="full" {...containerProps}>
              {children}
            </Container>
          </Box>
        </Flex>
      ) : (
        <Flex flex="1" h="full">
          <SideMenu w="220px" />
          <Box as="main" flex="1">
            <Container pl="220px" maxW="container.xl" overflow="scroll" h="full" {...containerProps}>
              {children}
            </Container>
          </Box>
        </Flex>
      )}
      <Footer />
    </Flex>
  );
};
