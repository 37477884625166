import type { BoxProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import type { ReactNode, VFC } from "react";

type Props = BoxProps & {
  children: ReactNode;
};

export const BaseCard: VFC<Props> = (props) => {
  const { children, ...styles } = props;
  return (
    <Box bg="white" w="330px" boxShadow="5px 5px 5px rgba(0, 0, 0, .4)" borderRadius="md" {...styles}>
      {children}
    </Box>
  );
};
