import { Box, HStack, Spacer, Square } from "@chakra-ui/react";
import type { VFC } from "react";
import { useReducer } from "react";
import { isMobile } from "react-device-detect";
import { Icon } from "src/components/ui/Icon";
import { DrawerMenu } from "src/components/ui/layout/DrawerMenu";
import { Logo } from "src/components/ui/text/Logo";

export const Header: VFC = () => {
  const [isOpen, toggleOpen] = useReducer((prev) => !prev, false);
  return (
    <HStack
      as="header"
      position="fixed"
      top="0"
      left="0"
      w="full"
      h="60px"
      bg="white"
      px="4"
      borderBottom="1px"
      borderColor="border.main"
      zIndex={100}
    >
      {isMobile ? (
        <>
          <Box w="40px" />
          <Spacer />
          <Logo />
          <Spacer />
          <Square size="40px" onClick={toggleOpen} cursor="pointer">
            <Icon name="hamburger" boxSize="6" />
            <DrawerMenu isOpen={isOpen} onClose={toggleOpen} />
          </Square>
        </>
      ) : (
        <>
          <Spacer />
          <Logo />
          <Spacer />
        </>
      )}
    </HStack>
  );
};
