import type { IconName } from "src/components/ui/Icon";
import type { ProductStatus } from "src/graphql/API";

export const pageTitle = {
  notFound: "ページが見つかりません",
  forbidden: "権限エラー",
  error: "サーバーエラー",
  orders: "注文一覧",
  orderDetail: "注文詳細",
  account: "アカウント情報",
  attention: "使用上の注意",
  releases: "リリースノート",
  manage: "管理",
} as const;

type MenuItem = { path: string; text: string; iconName: IconName };
/**
 * NOTE: スマホ(SP)表示の場合管理画面への導線を除去
 * @reason 現状の管理画面ではPC操作のみを想定しているため
 *
 * TODO: 画面アクセスとメニュー表示でロジックが２箇所になっているが、管理機能拡張のタイミングで最適なロジックを実装したい
 * @see https://github.com/ogawadenki/ogawa-finder/pull/119#discussion_r1156704888
 * @ref 画面アクセス制御：src/components/functional/AccessControl.tsx
 * */
export const menuItems = (isAdmin: boolean | null | undefined = false, isSp = false): MenuItem[] => {
  const list: (MenuItem | null)[] = [
    { path: "/orders", text: pageTitle.orders, iconName: "list" },
    isAdmin && !isSp ? { path: "/admin", text: pageTitle.manage, iconName: "manage" } : null,
    { path: "/account", text: pageTitle.account, iconName: "user" },
    { path: "/releases", text: pageTitle.releases, iconName: "notes" },
    { path: "/attention", text: pageTitle.attention, iconName: "attention" },
  ];
  const res = list.filter((v): v is NonNullable<MenuItem> => !!v);
  return res;
};

export const dateRanges = ["ALL", "LAST_SIX_MONTHS", "LAST_THREE_MONTHS", "MANUAL"] as const;

export const dateRangeText: Record<typeof dateRanges[number], string> = {
  ALL: "全期間",
  LAST_SIX_MONTHS: "過去６ヶ月",
  LAST_THREE_MONTHS: "過去３ヶ月",
  MANUAL: "手動入力",
};

export const statusValue: Record<ProductStatus, string> = {
  IN_PROGRESS: "注文済",
  ARRANGED: "手配済",
  SCHEDULED_DELIVERY: "納品予定",
  COMPLETED: "発送済",
  CANCELED: "キャンセル",
} as const;
