import {
  chakra,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
  Square,
  Text,
} from "@chakra-ui/react";
import Link from "next/link";
import type { VFC } from "react";
import { Icon } from "src/components/ui/Icon";
import { menuItems } from "src/constants";
import { useUserStore } from "src/store/useUserStore";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DrawerMenu: VFC<Props> = (props) => {
  const { onClose, isOpen } = props;
  const { user } = useUserStore();
  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent h="100vh">
        <DrawerHeader h="60px" p="0" borderBottomWidth="1px">
          <Flex justifyContent="space-between" alignItems="center" h="60px" px="4">
            <Spacer />
            <Square size="40px" onClick={onClose}>
              <Icon name="close" color="black" />
            </Square>
          </Flex>
        </DrawerHeader>
        <DrawerBody px="2">
          {menuItems(user?.isAdmin, true).map(({ path, text, iconName }) => (
            <Link key={path} href={path} passHref>
              <chakra.a outline="none">
                <Flex gap="16px" px="4" h="16" alignItems="center" borderBottom="1px" borderColor="white">
                  <Icon boxSize="8" name={iconName} color="primary.main" />
                  <Text pt="0.5" fontWeight="bold" size="md" color="secondary.main">
                    {text}
                  </Text>
                </Flex>
              </chakra.a>
            </Link>
          ))}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
