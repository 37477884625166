import { Flex, Text } from "@chakra-ui/react";
import type { VFC } from "react";

export const Footer: VFC = () => {
  return (
    <Flex as="footer" w="full" h="40px" bg="primary.main" alignItems="center" zIndex={100}>
      <Text textAlign="center" w="full" fontSize="xs" color="white" pt="1">
        Copyright &copy; OGAWA-DENKI.Co.,LTD. All rights reserved.
      </Text>
    </Flex>
  );
};
