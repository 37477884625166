import Head from "next/head";
import type { VFC } from "react";

type Props = {
  title: string;
};

export const HtmlHead: VFC<Props> = (props) => {
  const { title } = props;
  return (
    <Head>
      <title>{title} | Ogawa Finder</title>
    </Head>
  );
};
