import type { BoxProps } from "@chakra-ui/react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import type { VFC } from "react";
import { menuItems } from "src/constants";
import { useUserStore } from "src/store/useUserStore";

type Props = BoxProps;

export const SideMenu: VFC<Props> = (props) => {
  const { ...styles } = props;
  const router = useRouter();
  const isActive = (path: string) => router.asPath.includes(path);
  const { user } = useUserStore();

  return (
    <Box as="aside" position="fixed" h="full" bg="white" px="0" pb="40px" {...styles}>
      {menuItems(user?.isAdmin).map(({ path, text }) => (
        <Link key={path} href={path} passHref>
          <a>
            <Flex
              px="4"
              m="1"
              mb="0"
              h="10"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="md"
              bg={isActive(path) ? "primary.light" : "white"}
            >
              <Text size="md" color="primary.dark" fontWeight={isActive(path) ? "bold" : undefined}>
                {text}
              </Text>
            </Flex>
          </a>
        </Link>
      ))}
    </Box>
  );
};
